/**
****************************************************************************************
Project: Readabl
File: App.css
Description: This file contains styling for main view and media query were written
© Healthcare Triangle, Inc
****************************************************************************************
**/



.main-view {
    overflow: hidden;
}

.menu-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#simple-menu .MuiPaper-root {
    /* width: 189px; */
    min-width: 150px;
    padding: 0px 10px;
    height: auto;
    top: 47px !important; /* TODO: Find a way for better override */
}

.logout-item li {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}


#simple-menu .MuiMenu-list a {
    text-decoration: none;
    color: #000;
}

@media only screen and (max-width: 1440px) {
* {
    font-size: 13px;
}
}
@media only screen and (min-width: 1441px){
* {
    font-size: 13px;
}
}
@media only screen and (max-width: 800px){
  * {
      font-size: 13px;
  }
  }
  /*@media only screen and (min-width: 1900px){
    * {
        font-size: 20px;
    }
    }*/





